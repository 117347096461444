<template>
  <div class="issue-types" @drop.prevent @dragover.prevent>
    <v-toolbar flat>
      <v-toolbar-title>Editar Tipo de Incidencia</v-toolbar-title>
    </v-toolbar>
    <v-container fluid v-if="!getIssueTypesIsLoading">
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form" @submit="submit" class="new__issue-types__form ma-5">
        <v-row class="mb-2">
          <v-col>
            <v-text-field
              outlined
              :rules="rules.issueTypeName"
              label="*Nombre"
              class="mt-2"
              v-model="name"
              :error-messages="nameError"
              @keydown="nameError=null; error=null"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            <v-text-field outlined name="description" label="Descripción" v-model="description"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            <v-row @drop.prevent="addFile" @dragover.prevent>
              <v-col cols="2">
                <v-avatar size="88" color="grey">
                  <v-img v-if="iconUrl" :src="iconUrl"></v-img>
                  <v-icon v-else dark>mdi-image</v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-file-input
                  outlined
                  show-size
                  v-model="icon"
                  accept="image/png, image/jpeg, image/bmp"
                  label="*Icono"
                  placeholder="Elige un ícono"
                  :loading="isIconLoading"
                  @change="onIconChange"
                ></v-file-input>
              </v-col>
            </v-row>
            <p class="ml-2 mt-5">*Campos obligatorios</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              rounded
              outlined
              block
              color="primary"
              class="mt-5"
              :to="{name: 'issue-types'}"
            >Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              :loading="isLoading"
              type="submit"
              block
              color="primary"
              class="mt-5"
              :disabled="!isValid"
            >Actualizar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import { to } from '@/helpers'
import rules from '@/constants/input-validation'
import {
  FILES_UPLOAD_FILE,
  ISSUE_TYPES_UPDATE_ISSUE_TYPE,
  ISSUE_TYPES_GET_ISSUE_TYPE
} from '@/store/actions.type'
import { mapGetters } from 'vuex'
import { errorMessage } from '@/mixins'

export default {
  name: 'IssueTypeNew',
  props: {
    issueTypeId: {
      required: true
    }
  },
  created() {
    this.getIssueType()
  },
  data() {
    return {
      rules,
      isValid: false,
      isLoading: false,
      description: '',
      descriptionError: '',
      name: '',
      nameError: '',
      icon: null,
      iconUrl: null,
      wasIconChanged: false,
      currentIconUrl: null,
      iconError: '',
      isIconLoading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters([
      'getFilesFile',
      'getIssueTypesIssueType',
      'getIssueTypesIsLoading'
    ])
  },
  components: {},
  mixins: [errorMessage],
  methods: {
    back() {
      this.$router.push({ name: 'issue-types' })
    },
    async getIssueType() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(ISSUE_TYPES_GET_ISSUE_TYPE, this.issueTypeId)
      )
      if (err) {
        // @TODO: if 404
        this.error = err

        this.isLoading = false
      } else {
        this.name = this.getIssueTypesIssueType.name
        this.description = this.getIssueTypesIssueType.description
        this.iconUrl = this.getIssueTypesIssueType.iconUrl
        this.currentIconUrl = this.getIssueTypesIssueType.iconUrl
        this.isLoading = false
      }
    },
    async createAndUpload() {
      this.isIconLoading = true
      this.error = null
      const file = {
        file: this.icon,
        type: 'icons'
      }
      const [err] = await to(this.$store.dispatch(FILES_UPLOAD_FILE, file))
      if (err) {
        this.error = err
        this.isIconLoading = false
        if (err.status === 422) {
          this.fileError = this.errorMessage(err, 'type')
        }
      } else {
        this.wasIconChanged = true
        this.isIconLoading = false
      }
    },
    async submit(evt) {
      evt.preventDefault()
      const issueType = {
        id: this.issueTypeId,
        name: this.name,
        description: this.description,
        iconId: this.getFilesFile.id
      }
      if (this.isNewIcon) {
        issueType.iconId = this.getFilesFile.id
      }
      this.isLoading = true
      this.error = null
      const [err] = await to(
        this.$store.dispatch(ISSUE_TYPES_UPDATE_ISSUE_TYPE, issueType)
      )
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.nameError = this.errorMessage(err, 'name')
          this.descriptionError = this.errorMessage(err, 'description')
        }
      } else {
        this.$router.push({ name: 'issue-types' })
      }
    },
    onIconChange(file) {
      if (!file) {
        this.iconUrl = this.currentIconUrl
      }
    },
    addFile(e) {
      const droppedFile = e.dataTransfer.files[0]
      const admittedFileTypes = ['image/png', 'image/jpeg', 'image/bmp']
      if (!droppedFile) return
      if (admittedFileTypes.includes(droppedFile.type)) {
        this.icon = droppedFile
      }
    }
  },
  watch: {
    icon: function() {
      if (this.icon) {
        this.createAndUpload()
      }
    },
    getFilesFile: function() {
      if (this.getFilesFile.url) {
        this.iconUrl = this.getFilesFile.url
      } else {
        this.isNewIcon = false
      }
    }
  }
}
</script>
