export default {
  name: [value => !!value || 'Dato requerido'],
  password: [
    value => !!value || 'Dato requerido',
    v => v.length >= 8 || 'Mínimo de 8 caracteres',
    v =>
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,11}$/.test(
        v
      ) ||
      'Debe contener entre 8 y 11 caracteres con una letra mayúscula, una minúscula y un número.'
  ],
  email: [
    v => !!v || 'Dato requerido',
    v => /.+@.+/.test(v) || 'Ingresa un correo electrónico valido'
  ],
  issueTypeName: [
    value => !!value || 'Dato requerido'
  ],
  issueTypeIcon: [
    value => !!value || 'Dato requerido',
    value =>
      !value ||
      value.size < 1000000 ||
      'El tamaño del avatar debe ser inferior a 1MB'
  ]
}
