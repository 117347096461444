import { find, propEq, propOr, compose } from '@/helpers'
const mixin = {
  methods: {
    errorMessage(err, resource) {
      return compose(
        propOr(null, 'message'),
        find(propEq('resource', resource))
      )(err.errors)
    }
  }
}

export default mixin
