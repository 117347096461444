import DateFromNowFilter from '@/filters/date-from-now'
const mixin = {
  methods: {
    dateFromNow(date) {
      return DateFromNowFilter(date)
    }
  }
}

export default mixin
